<template>
  <div class="form">
    <div class="wrapper illustration illustration_shield">
      <Backlink title="questionnaire" service="mortgage" backlink="/mortgage/obligations" />
      <div class="content">
        <AboutObligations service="mortgage" nextlink="/mortgage/problems" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutObligations from '@/components/service/AboutObligations.vue'

export default {
  name: 'Mortgage_about_obligations',
  components: { Backlink, AboutObligations }
}
</script>
